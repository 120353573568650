import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {pairwise, startWith, Subscription} from "rxjs";
import {FirestoreService} from "../../../services/firestore.service";
import {ClientInContextService} from "../../../services/client-in-context.service";
import {AuthService} from "../../../services/auth.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SNACKBAR_CLASSES} from "../../../common/utils/utils";

@Component({
  selector: 'app-edit-asset-tracker-device-dialog',
  templateUrl: './edit-asset-tracker-device-dialog.component.html',
  styleUrl: './edit-asset-tracker-device-dialog.component.scss'
})
export class EditAssetTrackerDeviceDialogComponent implements OnInit, OnDestroy {
  editDeviceForm: UntypedFormGroup;
  loggedInUserFromAuthServiceSubscription: Subscription;
  loggedInUserDocData: any;
  private deviceRecordBeingEdited: any;
  allLocationsList: any[];
  selectedClientDocData: any;
  clientInContextServiceSubscription: Subscription;
  locationListSubscription: Subscription;
  devicesId: string;
  showAssetDetectionFields = false;

  constructor(
    private firestoreService: FirestoreService,
    private clientInContextService: ClientInContextService,
    public authService: AuthService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EditAssetTrackerDeviceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private ref: ChangeDetectorRef
  ) {
    this.deviceRecordBeingEdited = data.deviceRecord;
    this.devicesId = this.deviceRecordBeingEdited.id;
    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.subscribe(
      (userDocData) => {
        this.loggedInUserDocData = userDocData;
      }
    );

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;

      this.locationListSubscription = this.firestoreService
        .getAllUnarchivedLocationsForClientId(this.selectedClientDocData?.id)
        .subscribe((locationsList) => (this.allLocationsList = locationsList));
    });

  }

  ngOnInit() {
    this.editDeviceForm = this.fb.group({
      locationId: [{ value: this.deviceRecordBeingEdited.locationId ?? null,disabled: !this.selectedClientDocData.hasAdminRole}, []],
      notes: [{value: this.deviceRecordBeingEdited.notes, disabled: !this.selectedClientDocData.hasAdminRole}, null],
      name: [{value: this.deviceRecordBeingEdited.name ?? null, disabled: !this.selectedClientDocData.hasAdminRole}, []],
      serviceUrl: [{value: this.deviceRecordBeingEdited.serviceUrl ?? null, disabled: !this.selectedClientDocData.hasAdminRole}, []],
      assetTrackerAppMode: [{value: this.deviceRecordBeingEdited.assetTrackerAppMode ?? null, disabled: false}, []],
      assetDetectionExpirationMinutes: [this.deviceRecordBeingEdited.assetDetectionExpirationMinutes ? this.deviceRecordBeingEdited.assetDetectionExpirationMinutes : 15,
          [Validators.required]],
      assetDetectionRssiThreshold: [this.deviceRecordBeingEdited.assetDetectionRssiThreshold ? this.deviceRecordBeingEdited.assetDetectionRssiThreshold : -55,
          [Validators.required]],
    });
    if (this.editDeviceForm.controls.assetTrackerAppMode.value === 'TROLLEY') {
      this.showAssetDetectionFields = true;
    }

    this.editDeviceForm.controls.assetTrackerAppMode?.valueChanges
      .pipe(startWith(this.editDeviceForm.controls.value), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (next === 'TROLLEY') {
          this.showAssetDetectionFields = true;
        } else {
          this.showAssetDetectionFields = false;
        }
      })

  }

  async updateDevice() {
    if (this.editDeviceForm.pristine) {
      this.openSnackBar('No changes detected!', 'error');
      return;
    }

    if (!this.editDeviceForm.valid) {
      this.openSnackBar('Please fill all mandatory fields correctly', 'error');
      return;
    }

    const deviceDataToUpdate = this.editDeviceForm.value;
    try {
      deviceDataToUpdate.id = this.deviceRecordBeingEdited.id;

      if (!deviceDataToUpdate.notes) {
        deviceDataToUpdate.notes = null;
      }

      if (this.selectedClientDocData.hasAdminRole) {
        if (deviceDataToUpdate.locationId) {
          deviceDataToUpdate.locationName = this.allLocationsList
            .filter((location) => location.id === deviceDataToUpdate.locationId)
            .map((location) => (location.name ? location.name : null))[0];
        } else {
          deviceDataToUpdate.locationName = null;
          deviceDataToUpdate.locationId = null;
        }

        if (deviceDataToUpdate.assetTrackerAppMode === 'MAT') {
          deviceDataToUpdate.assetDetectionExpirationMinutes = null;
          deviceDataToUpdate.assetDetectionRssiThreshold = null;
        }
        await this.firestoreService.updateAssetTrackerDevice(deviceDataToUpdate);
      }

      this.openSnackBar('Changes have been saved', 'success');
      this.editDeviceForm.markAsPristine();
      this.dialogRef.close();
    } catch (error) {
      this.openSnackBar('Error in saving changes:' + error.message, 'error');
      console.log(error.message);
    }
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.clientInContextServiceSubscription?.unsubscribe();
    this.locationListSubscription?.unsubscribe();
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
}
