<div style="width: 600px; max-width: 100%;">
  <h2 mat-dialog-title> {{'SETTINGS.BEACON.EDITBEACON.TITLE.TEXT' | translate}}</h2>

  <mat-dialog-content [formGroup]="editBeaconForm" style="text-align: center;">
    <div class="row pb-4">
      <div class="col-lg-6">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.NAME.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.MACADDRESS.LABEL.TEXT' | translate}}</mat-label>
          <input matInput required mask="AA:AA:AA:AA:AA:AA" formControlName="mac">
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-6">
        <mat-form-field class="w-100">
        <mat-label>{{ 'SETTINGS.MODAL.TYPE.LABEL.TEXT' | translate }}</mat-label>
        <mat-select formControlName="type" required>
          <mat-option value="ASSET">Asset</mat-option>
          <mat-option value="LOCATION">Location</mat-option>
          <mat-option value="POSITION">Position</mat-option>
          <mat-option value="ROW">Row</mat-option>
          <mat-option value="TASK">Task</mat-option>
          <mat-option value="WORKER">Worker</mat-option>
        </mat-select>
      </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100" >
          <mat-label>{{ 'SETTINGS.MODAL.BEACONTYPE.LABEL.TEXT' | translate }}</mat-label>
          <mat-select formControlName="beaconType" required>
            <mat-option value="OT2">OT2</mat-option>
            <mat-option value="OT4P">OT4P</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div>
      <mat-dialog-actions class="w-100 px-0 justify-content-start">

        <button mat-stroked-button color="primary" (click)="resetAssignment()" [disabled]="!beaconRecordBeingEdited.assignedId">{{'SETTINGS.MODAL.RESETASSIGNING.BUTTON.TEXT' | translate}}</button>
      </mat-dialog-actions>
    </div>
    <div class="row pb-4">
      <div class="col-lg-6">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.ASSETTYPE.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="assetType">
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.ASSIGNEDID.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="assignedId">
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-6">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.ASSIGNEDNAME.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="assignedName">
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.STICKDURATION.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="stickDuration" type="number" class="stick">
          <div matSuffix class="mat-suffix">sec</div>
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-6">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.RSSITHRESHOLD.LABEL.TEXT' | translate}}</mat-label>
          <input matInput required formControlName="rssiThreshold" type="number" placeholder="0.00" step="0.1" class="rssi" max="0" min="-127">
          <div matSuffix class="mat-suffix">dBm</div>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.BATTERYLEFT.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="batteryLevel">
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <div class="pb-2 footer-with-Id">
    <div class="col-lg-12 d-flex copyEntityId">
      <input matInput [(ngModel)]="beaconsId" readonly #copyTarget >
      <button mat-icon-button appCopySuccess [iconTarget]="icon" color="primary" class="copy-inside-input"
        [ngxClipboard]="copyTarget">
        <mat-icon class="material-icons-outlined m-0" #icon>content_copy</mat-icon>
      </button>
    </div>
    <mat-dialog-actions>
      <button mat-stroked-button color="accent" (click)="close()">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' | translate}}</button>
      <button mat-raised-button color="primary" (click)="updateBeacon()" style="margin-left: 20px;">{{'SETTINGS.MODAL.SAVECHANGES.BUTTON.TEXT' | translate}}</button>
    </mat-dialog-actions>

  </div>
</div>
