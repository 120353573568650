import {Component, DestroyRef, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FirestoreService} from '../../../services/firestore.service';
import {ClientInContextService} from '../../../services/client-in-context.service';
import {AuthService} from '../../../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {SNACKBAR_CLASSES} from '../../../common/utils/utils';
import {generateApiKey} from 'generate-api-key';
import {environment} from '../../../../environments/environment.prod';

export interface MenuItem {
  displayName: string;
  url?: string;
  children?: MenuItem[];
}

interface Screen {
  displayName: string;
  urlParameter: string;
}

interface PresencesSortingOption {
  displayName: string;
  urlParameter: string;
}

interface Location {
  displayName: string;
  urlParameter: string;
}

@Component({
  selector: 'app-client-casting-keys',
  templateUrl: './client-casting-keys.component.html',
  styleUrls: ['./client-casting-keys.component.scss',
    '../../../common/styles/listing.scss']
})
export class ClientCastingKeysComponent implements OnInit, OnDestroy {
  autoArchiveShortSessions: boolean;
  private clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  unArchivedVsArchived = 'unarchived';
  displayedCastingKeysList: any[] = [];
  originalCastingKeysFromClientDoc: any;
  isCopied = false;

  // Casting URLs dropdown items
  menuItems: MenuItem[];
  screens: Screen[] = [
    {displayName: 'Presences', urlParameter: 'presences'},
    {displayName: 'Devices Battery Left', urlParameter: 'devices_battery'},
    {displayName: 'Devices Last Seen', urlParameter: 'devices_last_seen'}
  ];
  presencesSorting: PresencesSortingOption[] = [
    {displayName: 'Alphabetically', urlParameter: 'alphabetically'},
    {displayName: 'Worker Group', urlParameter: 'grouped'},
    {displayName: 'Current Activity', urlParameter: 'activity'},
  ];
  locations: Location[] = [];
  clientLocationsList: any[];
  locationsSubscription: Subscription;

  constructor(private firestoreService: FirestoreService,
              private clientInContextService: ClientInContextService,
              private authService: AuthService,
              private snackBar: MatSnackBar,
              private destroyRef: DestroyRef) {
    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(selectedClientDocData => {
        if (!selectedClientDocData) {
          return;
        }
        this.selectedClientDocData = selectedClientDocData;
        this.locationsSubscription = this.firestoreService
          .getAllUnarchivedLocationsForClientId(this.selectedClientDocData.id).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(locations => {
            this.locations = [{displayName: 'All', urlParameter: ''}];
            this.clientLocationsList =
              locations?.sort((locA: any, locB: any) => {
                return locA.name?.toLowerCase() < locB.name?.toLowerCase() ? -1 : locA.name?.toLowerCase() > locB.name?.toLowerCase() ? 1 : 0;
              })
                .map((loc: any) => {
                  this.locations.push({displayName: loc.name, urlParameter: loc.id});
                });

            const castingKeysFromClient = [];
            if (selectedClientDocData.castingKeys) {
              for (const castingKey of Object.keys(selectedClientDocData.castingKeys)) {
                castingKeysFromClient.push({
                  key: castingKey,
                  ...selectedClientDocData.castingKeys[castingKey]
                });
              }
            }
            this.originalCastingKeysFromClientDoc = castingKeysFromClient?.slice() ?? [];
            this.displayedCastingKeysList = castingKeysFromClient;
            this.castingURLSetting();
          });
      });
  }

  ngOnInit(): void {}

  // Casting url items settings
  castingURLSetting() {
    this.menuItems = [{displayName: 'Casting urls', children: []}];


    this.screens.forEach((screen) => {
      let baseUrl = `cast.opuswatch.nl/${screen.urlParameter}?`;
      if (environment.firebase.projectId === 'opustest-6d0c8') {
        baseUrl = 'dev.' + baseUrl;
      }

      const locationsToAdd: MenuItem[] = [];

      this.locations.forEach((location) => {
        const locationUrl = location.displayName === 'All' ? '' : `location_id=${location.urlParameter}`;
        if (screen.urlParameter === 'presences') {
          const sortingToAdd: MenuItem[] = [];
          this.presencesSorting.forEach((sorting) => {
            sortingToAdd.push({
              displayName: sorting.displayName,
              url: `${baseUrl}${locationUrl}&order_by=${sorting.urlParameter}`,
            });
          });
          locationsToAdd.push({
            displayName: location.displayName,
            children: sortingToAdd,
          });
        } else {
          locationsToAdd.push({
            displayName: location.displayName,
            url: `${baseUrl}${locationUrl}`,
          });
        }
      });

      const menuItem: MenuItem = {
        displayName: screen.displayName,
        children: locationsToAdd,
      };

      this.menuItems[0]?.children?.push(menuItem);
      //console.log('menu item is', this.menuItems)
    });
  }

  onCopySuccess() {
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false
    }, 500);
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }


  reloadClientInContext() {
    const clientSubscription = this.firestoreService.getClientById(this.selectedClientDocData.id).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((clientFromDB) => {
        const loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((userDocData) => {
            if (userDocData) {
              const clientElementInUserDoc = userDocData?.clients.filter(client => client.clientId === clientFromDB.id);
              if (clientElementInUserDoc && clientElementInUserDoc.length > 0) {
                clientFromDB.role = clientElementInUserDoc[0]?.role;
              }
              this.clientInContextService.clientInContextSubject.next(clientFromDB);
              loggedInUserFromAuthServiceSubscription?.unsubscribe();
              clientSubscription?.unsubscribe();
            }
          });
      });
  }

  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
    this.locationsSubscription.unsubscribe();
  }

  areArchivedCastingKeysShown() {
    return (this.unArchivedVsArchived === 'archived');
  }

  areUnArchivedCastingKeysShown() {
    return (this.unArchivedVsArchived === 'unarchived');
  }

  createNewCastingKey() {
    const newCastingKeyObject: any = {
      key: generateApiKey({
        method: 'string',
        length: 32,
        pool: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890'
      }),
      isArchived: false
    }
    this.displayedCastingKeysList.push(newCastingKeyObject);
  }

  async archiveCastingKey($event, castingKey, isArchival) {
    if (this.originalCastingKeysFromClientDoc.filter(castingKeyRecord => castingKeyRecord.key === castingKey.key).length === 0) {
      //Casting key is not saved yet in Firestore
      this.displayedCastingKeysList.filter(castingKeyRecord => castingKeyRecord.key === castingKey.key)[0].isArchived = isArchival;
      return;
    }
    this.displayedCastingKeysList.filter(castingKeyRecord => castingKeyRecord.key === castingKey.key)[0].isArchived = isArchival;
    const clientUpdateObject = this.mapCastingKeysInMemoryToFSFormat();
    try {
      await this.firestoreService.updateClientById(this.selectedClientDocData.id, clientUpdateObject);
      this.reloadClientInContext();
      if (isArchival) {
        this.openSnackBar('Casting key archived successfully', 'success');
      } else {
        this.openSnackBar('Casting key unarchived successfully', 'success');
      }
    } catch (error) {
      console.log('Error in archiving/unarchiving casting key:' + JSON.stringify(error));
      if (isArchival) {
        this.openSnackBar('Error in archiving casting key:' + error.message, 'failure');
      } else {
        this.openSnackBar('Error in unarchiving casting key:' + error.message, 'failure');
      }
    }
  }

  async saveCastingKeys() {
    try {
      const clientUpdateObject = this.mapCastingKeysInMemoryToFSFormat();
      await this.firestoreService.updateClientById(this.selectedClientDocData.id, clientUpdateObject);
      this.reloadClientInContext();
      this.openSnackBar('Casting keys saved successfully', 'success');
    } catch (error) {
      console.log('Error in saving casting keys:' + JSON.stringify(error));
      this.openSnackBar('Error in saving casting keys:' + error.message, 'failure');
    }

  }

  mapCastingKeysInMemoryToFSFormat() {
    const clientUpdateObject: any = {};
    const castingKeysObj: any = {};
    for (const castingKeyEntry of this.displayedCastingKeysList) {
      castingKeysObj[castingKeyEntry.key] = {
        isArchived: castingKeyEntry.isArchived,
      }
    }
    clientUpdateObject.castingKeys = castingKeysObj;
    clientUpdateObject.castingKeyIds = this.displayedCastingKeysList.filter(castingKey => !castingKey.isArchived).map(castingKey => castingKey.key);
    return clientUpdateObject;
  }

  cancelClicked() {
    this.displayedCastingKeysList = this.originalCastingKeysFromClientDoc.filter(castingKey => castingKey.isArchived === this.areArchivedCastingKeysShown());
  }

  getCastingKeysToDisplay() {
    if (this.areUnArchivedCastingKeysShown()) {
      return this.displayedCastingKeysList.filter(castingKey => !castingKey.isArchived);
    } else {
      return this.displayedCastingKeysList.filter(castingKey => castingKey.isArchived);
    }
  }

}

