<mat-menu #childMenu="matMenu" [overlapTrigger]="false" backdropClass="casting-vertical-sub-menu" panelClass="casting-menu-items">
  <span *ngFor="let child of items" class="">
    <!-- Handle branch node menu items -->
    <span *ngIf="child.children">
      <button class="py-2"
        mat-menu-item
        color="primary"
        [matMenuTriggerFor]="menu.childMenu"
      >
        <span>{{ child.displayName }}</span>
      </button>
      <app-casting-menu-item #menu [key]="castingKey" [items]="child.children"></app-casting-menu-item>
    </span>
    <!-- Handle leaf node menu items -->
    <span *ngIf="!child.children">
      <button mat-menu-item (click)="copyUrl(child.url)" class="py-2">
        <span>{{ child.displayName }}</span
        ><br />
        <small
          ><span>{{ child.url }}</span></small
        >
        <mat-icon>content_copy</mat-icon>
      </button>
    </span>
  </span>
</mat-menu>
