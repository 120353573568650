 <div class="page-action listing-container-roles ">
  <mat-card class="transparent-effect-card mb-5">
    <mat-card-header class="max-width-1200">
      <mat-card-title class="d-flex align-items-center">
        <mat-icon class="material-symbols-outlined mr-2">
          person
      </mat-icon>{{'SETTINGS.USERPROFILE.UPDATENAME.TITLE.TEXT' | translate}}
    </mat-card-title>
    </mat-card-header>

    <mat-card-content class="max-width-1200">
      <div class="mt-3">
        <mat-form-field appearance="outline" class="w-100">
          <input matInput required
                 placeholder="Name"
                 [(ngModel)]="name"
          >
      </mat-form-field>
      </div>
      <div class="mt-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'SETTINGS.MODAL.EMAIL.LABEL.TEXT' | translate}}</mat-label>
          <input matInput disabled required
          placeholder="Emal"
          [(ngModel)]="email">
        </mat-form-field>
      </div>
      <div class="mt-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'SETTINGS.MODAL.NOTES.LABEL.TEXT' | translate}}</mat-label>
          <!-- <input matInput formControlName="notes"> -->
          <textarea matInput placeholder="Notes"
          [(ngModel)]="notes"></textarea>
        </mat-form-field>
      </div>
      <div class="mt-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'SETTINGS.MODAL.LANGUAGE.LABEL.TEXT' | translate}}</mat-label>
          <mat-select placeholder="Language"
          [(ngModel)]="languageCode">
            <mat-option *ngFor="let language of languagesList" [value]="language.code">{{ language.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="mt-3 d-flex justify-content-between">

        <div style="margin-right: 16px; display:flex; flex-direction: column;">
          <mat-slide-toggle  [(ngModel)]="clientNamesBlurred" (change)="updateBlurredSettingForUser()">
            <mat-label style="font-size: 1rem;font-weight: 300;"> Client names blurred</mat-label>
          </mat-slide-toggle>
        </div>

        <button mat-raised-button color="primary" class="ml-3" (click)="updateUser()">{{'SETTINGS.SAVECHANGES.BUTTON.TEXT' | translate}}</button>
      </div>
    </mat-card-content>


  </mat-card>

  <mat-card class="listing-container-roles transparent-effect-card">
    <mat-card-header>
      <mat-card-title class="d-flex align-items-center">
        <mat-icon class="material-symbols-outlined mr-2">
          domain
      </mat-icon>{{'SETTINGS.USERPROFILE.CLIENTROLE.TITLE.TEXT' | translate}}
    </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div class="user-list mt-3">
        <div class="user-items transparent-effect-card" *ngFor="let item of userList">
          <mat-icon class="material-symbols-outlined">
            person
          </mat-icon>
          <div class="d-flex flex-column">
            <span class="user-name" [ngClass]="{'blurredName': clientNamesBlurred}">{{item.clientName}}</span>
            <span class="user-role">{{item.role}}</span>
          </div>
        </div>
      </div>
    </mat-card-content>


</mat-card>
</div>

