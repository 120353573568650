import {Directive, ElementRef, Renderer2, HostListener, Output, EventEmitter, Input} from '@angular/core';

@Directive({
  selector: '[appCopySuccess]'
})
export class CopySuccessDirective {
  @Output() copySuccess = new EventEmitter<void>();
  @Input() iconTarget: any

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  @HostListener('click', ['$event'])
  onCopyClick(event: MouseEvent): void {
    this.onCopySuccess();
  }

  private onCopySuccess(): void {
    this.copySuccess.emit();
    this.renderer.addClass(this.el.nativeElement, 'copied');
    this.iconTarget._elementRef.nativeElement.textContent = 'check'
    setTimeout(() => {
      this.renderer.removeClass(this.el.nativeElement, 'copied');
      this.iconTarget._elementRef.nativeElement.textContent = 'content_copy'
    }, 600);
  }
}
