
<ng-container *ngIf="!isTopFilter">
  <button mat-menu-item (click)="sortList(column.name,'asc')">
    <mat-icon></mat-icon>
    <span innerHTML="{{'DASHBOARD.PAGESORT.ATOZ.LABEL.TEXT' |  translate}}"></span>
  </button>
  <button mat-menu-item (click)="sortList(column.name,'desc')">
    <mat-icon></mat-icon>
    <span innerHTML="{{'DASHBOARD.PAGESORT.ZTOA.LABEL.TEXT' |  translate}}"></span>
  </button>
  <form class="example-form px-3" (click)="$event.stopPropagation()">
    <mat-form-field class="filter" appearance="outline">
      <mat-label>{{'DASHBOARD.TABLE.FILTERVALULE.LABEL.TEXT' | translate}}</mat-label>
      <input type="text" placeholder="" aria-label="Filter Value" matInput
        [ngModelOptions]="{standalone: true}" [(ngModel)]="column.filterValue"
        [matAutocomplete]="auto" (keyup)="headerFilter($event, column.name)"
        (focus)="headerFilter($event, column.name)" />
        <button mat-icon-button matSuffix (click)="removeFilters()">
          <mat-icon>close</mat-icon>
        </button>
      
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of column.filterOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  <button mat-menu-item (click)="removeFilters()">
    <mat-icon>filter_list_off</mat-icon>
    <span> {{'DASHBOARD.TABLE.REMOVEALLFILTERS.LABEL.TEXT' | translate}}</span>
  </button>
</ng-container>


<ng-container *ngIf="isTopFilter">
  <form class="example-form" (click)="$event.stopPropagation()" *ngFor="let filter of displayedTopFilters">
    <mat-form-field class="filter px-3" appearance="outline">
      <mat-label>{{filter.displayName}}</mat-label>
      <input type="text" placeholder="" aria-label="Number" matInput [ngModelOptions]="{standalone: true}"
        [(ngModel)]="filter.filterValue" [matAutocomplete]="auto" (keyup)="headerFilter($event, filter.name)"
        (focus)="headerFilter($event, filter.name)" />
      <mat-icon matSuffix>search</mat-icon>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filter.filterOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  <button mat-menu-item (click)="removeFilters()">
    <mat-icon>filter_list_off</mat-icon>
    <span>{{'DASHBOARD.COMMON.REMOVEALLFILTER.BUTTON.TEXT' | translate}}</span>
  </button>
</ng-container>