import {saveAs} from 'file-saver';
import * as ExcelJS from 'exceljs';
import {Workbook, Worksheet, Table, TableColumnProperties} from 'exceljs';
import moment, {Moment} from 'moment';

export class ExcelUtils {
  /**
   * Generates an Excel file from a given workbook and triggers a download.
   *
   * @param workbook - The ExcelJS Workbook object.
   * @param fileName - The desired name for the downloaded Excel file (optional).
   */
  static generateExcel(workbook: Workbook, fileName = 'FileName') {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, `${fileName}.xlsx`);
    });
  }

  /**
   * Adjusts the width of all columns in a worksheet to fit the content of their cells.
   *
   * This method iterates through each column and cell in the worksheet,
   * calculating the maximum length of cell content (as a string).
   * It then sets the column width to the maximum length or a minimum of 10,
   * whichever is larger.
   *
   * @param sheet - The ExcelJS Worksheet object to adjust.
   */
  static fitColumnsToContent(sheet: Worksheet) {
    sheet.columns.forEach(column => {
      if (column.values) {
        const lengths = column.values.map(v => v?.toString().length);
        const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
        column.width = maxLength;
      }
    });
  }

  /**
   * Extracts unique numbers from a string.
   *
   * @param str - The input string containing numbers prefixed with '#'.
   * @returns An array of unique numbers extracted from the string.
   *
   * @example
   * extractUniqueNumbersFromString("This string has #123 and #456, and #123 again.")
   * // returns [123, 456]
   */
  static extractUniqueNumbersFromString(str: string): number[] {
    const regex = /#(\d+)/g;
    let match;
    const numbers: number[] = [];

    // tslint:disable-next-line:no-conditional-assignment
    while ((match = regex.exec(str)) !== null) {
      const numberVal = parseInt(match[1], 10);
      if (!numbers.includes(numberVal)) {
        numbers.push(numberVal);
      }
    }

    return numbers;
  }

  /**
   * Converts a Moment.js object to an Excel time value.
   *
   * @param time - The Moment.js object representing the time.
   * @returns The Excel time value (a fraction of 24 hours).
   */
  static convertMomentToExcelTime(time: Moment): number {
    const hour = time.hour();
    const minute = time.minute();
    const timeFraction = 24 * 60;
    return (
      Math.round((hour / 24 + minute / timeFraction) * timeFraction) /
      timeFraction
    );
  }

  static convertTimeStringToExcelTime(timeString: string): number {
    const [hours, minutes] = timeString.split(':').map(Number); // Split and convert to numbers
    const timeFraction = 24 * 60;
    return (
      Math.round((hours / 24 + minutes / timeFraction) * timeFraction) /
      timeFraction
    );
  }

  /**
   * Sets the print area for a worksheet to include all content and fits the content to a single page.
   *
   * @param sheet - The ExcelJS Worksheet object.
   */
  static setPrintArea(sheet: Worksheet) {
    const rowCount = sheet.rowCount;
    const columnCount = sheet.columnCount;

    sheet.pageSetup.printArea = `A1:${this.columnIndexToLetter(
      columnCount
    )}${rowCount}`;
    sheet.pageSetup.fitToPage = true;
  }

  /**
   * Converts a column index to its corresponding letter representation (e.g., 1 -> A, 2 -> B, 27 -> AA).
   *
   * @param n - The column index (1-based).
   * @returns The column letter.
   */
  static columnIndexToLetter(n: number): string {
    const ordA = 'a'.charCodeAt(0);
    const ordZ = 'z'.charCodeAt(0);
    const len = ordZ - ordA + 1;

    let s = '';
    while (n >= 0) {
      s = String.fromCharCode((n % len) + ordA) + s;
      n = Math.floor(n / len) - 1;
    }
    return s.toUpperCase();
  }

  /**
   * Extracts the row number from a cell address (e.g., "A1" -> 1, "B12" -> 12).
   *
   * @param cellAddress - The cell address string.
   * @returns The extracted row number.
   */
  static extractRowNumberFromCell(cellAddress: string): number {
    const match = cellAddress.match(/\d+$/);
    if (match) {
      return parseInt(match[0], 10);
    } else {
      return 0;
    }
  }

  /**
   * Formats columns in a worksheet to display time values in the format "[h]:mm".
   *
   * @param sheet - The ExcelJS Worksheet object.
   * @param columns - An array representing the columns to format.
   *                  This could be an array of column indices or an array
   *                  that reflects the structure of your data (where each
   *                  element represents a column).
   */
  static formatTimeColumns(sheet: Worksheet, columns: any[]) {
    columns.forEach((value, column) => {
      if (column > 0) {
        sheet.getColumn(column + 1).numFmt = '[h]:mm';
      }
    });
  }

  /**
   * Generates a calculated column in an ExcelJS table.
   *
   * @param sheet - The worksheet containing the table.
   * @param table - The ExcelJS Table object.
   * @param name - The name of the new column.
   * @param totalsRowFunction - The function to use for the totals row (e.g., 'sum', 'average').
   * @param filterButton - Whether to show a filter button in the column header.
   * @param columnIndex - The index where the new column should be inserted.
   * @param rowLength - The number of data rows in the table.
   * @param formula - The formula for the calculated column (use '#1', '#2', etc. as placeholders for column indices).
   * @param numFmt - The number format for the column.
   */
  static generateCalculatedColumn(
    sheet: Worksheet,
    table: Table,
    name: string,
    totalsRowFunction: TableColumnProperties['totalsRowFunction'],
    filterButton: boolean,
    columnIndex: number,
    rowLength: number,
    formula: string,
    numFmt: string
  ) {
    const columnLetter = this.columnIndexToLetter(columnIndex);
    table.addColumn({name, totalsRowFunction, filterButton}, [], columnIndex);
    table.commit();

    const startRow = this.extractRowNumberFromCell(table.ref);
    const endRow = startRow + rowLength;

    const columnsToReplace = this.extractUniqueNumbersFromString(formula);

    for (let rowNumber = startRow + 1; rowNumber <= endRow; rowNumber++) {
      let constructedFormula: string = formula;


      /*columnsToReplace.forEach((columnIndex) => {
        const returnedRowChar = this.columnIndexToLetter(columnIndex - 1);
        // @ts-ignore
        constructedFormula = constructedFormula.replaceAll(
          `#${columnIndex}`,
          `${returnedRowChar}${rowNumber}`
        );
      });*/
      const replacements = {}; // Use a temporary object for replacements

      columnsToReplace.forEach((columnIndex) => {
        const returnedRowChar = this.columnIndexToLetter(columnIndex - 1);
        replacements[`#${columnIndex}`] = `${returnedRowChar}${rowNumber}`;
      });

      constructedFormula = constructedFormula.replace(/#(\d+)/g, (match, num) => replacements[`#${num}`] || match);

      sheet.getCell(`${columnLetter}${rowNumber}`).value = {
        formula: constructedFormula,
      };
    }

    sheet.getColumn(columnIndex + 1).numFmt = numFmt;
  }

  static applyFormulaToColumn(
    worksheet: ExcelJS.Worksheet,
    columnNumber: number, // 1-based column index
    formulaWithHashes: string, // Formula containing #1, #2, etc. for column references
    optionalColumns: number[] = [] // 1-based column indices of optional columns
  ) {
    const lastRow = worksheet.actualRowCount;
    if (lastRow < 2) { return; } // Don't do anything if there are less than 2 rows
    const columnLetter = ExcelUtils.columnIndexToLetter(columnNumber);

    const columnsToReplace = ExcelUtils.extractUniqueNumbersFromString(formulaWithHashes);

    for (let rowNumber = 2; rowNumber < lastRow; rowNumber++) { // Start from row 2, end at 2nd to last row
      let finalFormula = formulaWithHashes;
      let skipFormula = false; // Initialize skipFormula to false for each row


      columnsToReplace.forEach((colIndex) => {
        const colLetter = ExcelUtils.columnIndexToLetter(colIndex);
        const cell = worksheet.getCell(`${colLetter}${rowNumber}`);
        const cellValue = cell.value;

        if (optionalColumns.includes(colIndex) && (cellValue === "" || cellValue === "-" || cellValue == null)) {
          finalFormula = finalFormula.replaceAll(`#${colIndex}`, `SUM(${colLetter}${rowNumber})`); // Replace with 0 if optional and empty/invalid
        } else if (cellValue === "" || cellValue === "-" || cellValue == null) {
          skipFormula = true;
        } else {
          finalFormula = finalFormula.replaceAll(`#${colIndex}`, `SUM(${colLetter}${rowNumber})`);
        }
      });

      if (skipFormula) {
        worksheet.getCell(`${columnLetter}${rowNumber}`).value = "-";
      } else {
        worksheet.getCell(`${columnLetter}${rowNumber}`).value = { formula: finalFormula };
      }

    }
  }
}
