<div style="min-width: 400px; max-width: 500px;">
  <mat-dialog-content [formGroup]="enrollmentSettingsForm" style="text-align: center; max-height: 80vh;">
    <mat-form-field class="w-100 mb-3">
      <mat-label>{{'SETTINGS.MODAL.SELECTLOCATION.LABEL.TEXT' | translate}}</mat-label>
      <mat-select formControlName="locationId">
        <mat-option [value]="null">{{'SETTINGS.MODAL.SELECTLOCATION.LABEL.TEXT' | translate}}</mat-option>
        <mat-option *ngFor="let location of allLocationsList" [value]="location.id">
          {{location.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-100 mb-3">
      <mat-label> {{'SETTINGS.MODAL.APPMODES.LABEL.TEXT' | translate}}</mat-label>
      <mat-select formControlName="appModes" multiple>
        <mat-option *ngFor="let appMode of allAppModesList" [value]="appMode.id">
          {{appMode.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-100 mb-3">
      <mat-label> {{'SETTINGS.MODAL.NEXTDEVICENUMBER.LABEL.TEXT' | translate}}</mat-label>
      <input matInput formControlName="nextDeviceNumber" />
    </mat-form-field>
    <span style="color: #EC4444; font-size: 0.7rem;font-style: italic; display: block; margin-top: -10px; text-align: left;"
      *ngIf="enrollmentSettingsForm.controls['nextDeviceNumber'].invalid">
      {{'SETTINGS.MODAL.DEVICENUMBERERROR.LABEL.TEXT' | translate}}
    </span>
    <span style="color: #EC4444; font-size: 0.7rem;font-style: italic; display: block; margin-top: -10px; text-align: left;"
      *ngIf="enrollmentSettingsForm.controls['nextDeviceNumber'].valid">
      &nbsp;
    </span>
    <div class="toggler-options d-flex flex-column mb-3">
      <mat-slide-toggle class="edit_device_toggles text-left ml-0"
        formControlName="enableAutoTouchLock">
        <mat-label class="mb-0 ml-2">{{'SETTINGS.TABLEHEAD.ENABLELOCK.TITLE.TEXT' | translate}}</mat-label>
      </mat-slide-toggle>
      <mat-slide-toggle class="edit_device_toggles text-left ml-0" formControlName="enableGPS">
        <mat-label class="mb-0 ml-2">{{'SETTINGS.TABLEHEAD.ENABLEGPS.TITLE.TEXT' | translate}}</mat-label>
      </mat-slide-toggle>
      <mat-slide-toggle class="edit_device_toggles text-left ml-0" formControlName="enableOutOfRange">
        <mat-label class="mb-0 ml-2">{{'SETTINGS.TABLEHEAD.ENABLERANGE.TITLE.TEXT' | translate}}</mat-label>
      </mat-slide-toggle>
      <mat-slide-toggle class="edit_device_toggles text-left ml-0" formControlName="enableTrainingMode">
        <mat-label class="mb-0 ml-2">{{'SETTINGS.TABLEHEAD.ENABLEMODE.TITLE.TEXT' | translate}}</mat-label>
      </mat-slide-toggle>
      <mat-slide-toggle class="edit_device_toggles text-left ml-0" formControlName="enableVoiceAssistance">
        <mat-label class="mb-0 ml-2">{{'SETTINGS.TABLEHEAD.ENABLEVOICE.TITLE.TEXT' | translate}}</mat-label>
      </mat-slide-toggle>
    </div>

  </mat-dialog-content>
  <div style="text-align: right;margin-bottom: 0.4rem;">
    <mat-dialog-actions>
      <button mat-stroked-button color="accent" (click)="close()"
        [disabled]="beingSaved">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' | translate}}</button>
      <button mat-raised-button color="primary" (click)="updateEnrollmentSettings()"
        [disabled]="beingSaved || enrollmentSettingsForm?.invalid"
        style="margin-left: 20px;">{{'SETTINGS.MODAL.SAVECHANGES.BUTTON.TEXT' | translate}}</button>
    </mat-dialog-actions>
  </div>
</div>
