import {
  AfterViewInit, ChangeDetectorRef,
  Component, DestroyRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatSort} from '@angular/material/sort';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatTooltipModule, TooltipPosition} from '@angular/material/tooltip';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {
  DeviceMainAttributes,
  DeviceRemainingAttributes,
} from '../../common/interfaces/device-interfaces';
import {FirestoreService} from '../../services/firestore.service';
import {ActivateDeviceDialogComponent} from './activate-device-dialog/activate-device-dialog.component';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {Subscription} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {DatePipe} from '@angular/common';
import {EditDeviceDialogComponent} from './edit-device-dialog/edit-device-dialog.component';
import firebase from 'firebase/compat/app';
import {ClientInContextService} from '../../services/client-in-context.service';
import {ConfirmationDialogComponent} from '../utility/confirmation-dialog/confirmation-dialog.component';
import {Timestamp} from 'firebase/firestore';
import {MultipleDeviceEditComponent} from './multiple-device-edit-dialog/multiple-device-edit.component';
import {SNACKBAR_CLASSES} from '../../common/utils/utils';
import {EnrollmentSettingsDialogComponent} from './enrollment-settings-dialog/enrollment-settings-dialog.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {EditAssetTrackerDeviceDialogComponent} from './edit-asset-tracker-device-dialog/edit-asset-tracker-device-dialog.component';
import {FilterProcessingService} from 'src/app/services/filter-processing.service';

const moment = require('moment-timezone');
const momentDurationFormatSetup = require('moment-duration-format');
momentDurationFormatSetup(moment);

@Component({
  selector: 'app-client-device',
  templateUrl: './client-device.component.html',
  styleUrls: [
    './client-device.component.scss',
    '../../common/styles/listing.scss',
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ClientDeviceComponent implements AfterViewInit, OnDestroy {
  unArchivedVsArchived = 'unarchived';
  datePipe = new DatePipe('en-US');


  deviceRemainingAttributesReadableMap: Map<string, string> = new Map([
    ['id', 'SETTINGS.TABLEHEAD.DEVICEID.TITLE.TEXT'],
    ['lastActivityTimestamp', 'SETTINGS.TABLEHEAD.LASTACTIVITYTIME.TITLE.TEXT'],
    ['lastActivityName', 'SETTINGS.TABLEHEAD.LASTACTIVITY.TITLE.TEXT'],
    ['added', 'SETTINGS.TABLEHEAD.ADDED.TITLE.TEXT'],
    ['lastGeoLocation', 'SETTINGS.TABLEHEAD.LASTGEOLOCATION.TITLE.TEXT'],
    ['lastRowNumber', 'SETTINGS.TABLEHEAD.LASTROW.TITLE.TEXT'],
    ['enableAutoTouchLock', 'SETTINGS.TABLEHEAD.ENABLELOCK.TITLE.TEXT'],
    ['enableGPS', 'SETTINGS.TABLEHEAD.ENABLEGPS.TITLE.TEXT'],
    ['enableOutOfRange', 'SETTINGS.TABLEHEAD.ENABLERANGE.TITLE.TEXT'],
    ['enableTrainingMode', 'SETTINGS.TABLEHEAD.ENABLEMODE.TITLE.TEXT'],
    ['enableVoiceAssistance', 'SETTINGS.TABLEHEAD.ENABLEVOICE.TITLE.TEXT'],
    ['appModes', 'SETTINGS.TABLEHEAD.APPMODE.TITLE.TEXT'],
    ['lastFlowerPickerWorkerName', 'DASHBOARD.TABLEHEAD.LASTFLOWERPICKER.TITLE.TEXT'],
    ['lastSorterWorkerName', 'DASHBOARD.TABLEHEAD.LASTSORTER.TITLE.TEXT'],
    ['lastVarietyName', 'SETTINGS.TABLEHEAD.LASTROW.TITLE.TEXT'],
    ['lastAsset', 'SETTINGS.TABLEHEAD.LASTASSET.TITLE.TEXT'],
    ['lastSessionId ', 'SETTINGS.TABLEHEAD.LASTSESSION.TITLE.TEXT'],
    ['serialNumber', 'SETTINGS.TABLEHEAD.SERIALNUMBER.TITLE.TEXT'],
    ['memory', 'SETTINGS.TABLEHEAD.MEMORY.TITLE.TEXT'],
  ]);

  columnsToDisplay: any[] = [
    {
      name: 'deviceNumber',
      displayName: 'SETTINGS.TABLEHEAD.DEVICENUMBER.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
      isBatteryStatus: false
    },
    {
      name: 'lastWorkerName',
      displayName: 'SETTINGS.TABLEHEAD.LASTWORKER.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
      isBatteryStatus: false
    },
    {
      name: 'locationName',
      displayName: 'SETTINGS.TABLEHEAD.ASSIGNEDLOCATION.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
      isBatteryStatus: false
    },
    {
      name: 'appVersion',
      displayName: 'SETTINGS.TABLEHEAD.APPVERSION.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
      isBatteryStatus: false
    },
    {
      name: 'batteryLeft',
      displayName: 'Battery Lef',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
      isBatteryStatus: true
    },
    {
      name: 'qc',
      displayName: 'SETTINGS.TABLEHEAD.DATAQC.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
      isBatteryStatus: false
    },
  ];
  displayedTopFilters: any[] = this.columnsToDisplay.filter(
    (column) => column.showTopFilter
  );
  columnsHeadersToDisplay: string[] = [
    'select',
    'deviceIcon',
    'lockIcon',
    'deviceNumber',
    'lastWorkerName',
    'locationName',
    'appVersion',
    'lastSeenTimestamp',
    'batteryLeft',
    'qc',
    'issue',
    'edit'
  ];
  dateColumns: string[] = [
    'added',
    'lastActivityTimestamp',
    'lastSeenTimestamp'
  ];
  dataSource: MatTableDataSource<DeviceMainAttributes>;
  expandedElement: any = null;
  filterValue: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public deviceList: any[];
  loggedInUserFromAuthServiceSubscription: Subscription;
  loggedInUserDocData: any;
  selectedClientDocData: any;
  clientId: string;
  selectAll = false;
  clientInContextServiceSubscription: Subscription;
  unArchivedDevicesSubscription: Subscription;
  archivedDevicesSubscription: Subscription;
  beingArchived = false;
  lastSeenRequestInProgress = false;

  constructor(
    public firestoreService: FirestoreService,
    private dialog: MatDialog,
    public authService: AuthService,
    public route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private clientInContextService: ClientInContextService,
    private changeDetectorRef: ChangeDetectorRef,
    private destroyRef: DestroyRef, private filterProcessService: FilterProcessingService) {
    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((userDocData) => {
        this.loggedInUserDocData = userDocData;
      });
    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(selectedClientDocData => {
        if (!selectedClientDocData) {
          return;
        }
        this.selectedClientDocData = selectedClientDocData;
        this.fetchUnarchivedDevices();
      });
  }

  ngAfterViewInit() {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    //dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    this.dialog.open(ActivateDeviceDialogComponent, dialogConfig);
  }

  openEditDialog($event, deviceRecord) {
    $event.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    //dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      deviceRecord,
    };
    if (deviceRecord.deviceType === 'ASSET_TRACKER') {
      this.dialog.open(EditAssetTrackerDeviceDialogComponent, dialogConfig);
    } else {
      this.dialog.open(EditDeviceDialogComponent, dialogConfig);
    }
  }

  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.unArchivedDevicesSubscription?.unsubscribe();
    this.archivedDevicesSubscription?.unsubscribe();
  }

  openSnackBar(message) {
    this.snackBar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  toggleArchived() {
    if (this.areUnArchivedDevicesShown()) {
      this.fetchUnarchivedDevices();
    } else {
      this.fetchArchivedDevices();
    }
  }

  fetchArchivedDevices() {
    this.deviceList = [];
    this.unArchivedDevicesSubscription?.unsubscribe();
    this.archivedDevicesSubscription =
      this.firestoreService.getArchivedDevicesForClientId(this.selectedClientDocData.id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((devicesList) => {
          const currentlySelectedDeviceIds = (this.deviceList && this.deviceList.length > 0) ? this.deviceList.filter(device => device.isSelected).map(device => device.id) : [];
          this.deviceList = devicesList.map((device) => {
            device.deviceId = device.id;
            device.isSelected = currentlySelectedDeviceIds.includes(device.id);
            if (device.hasOwnProperty('isCharging') && device.isCharging) {
              if (device.lastBatteryLeft) {
                device.batteryLeft = (device.lastBatteryPercentage + '%' ?? '');
              } else {
                device.batteryLeft = '';
              }
            } else {
              if (device.lastBatteryLeft) {
                device.batteryLeft = (device.lastBatteryPercentage + '%' ?? '') + ' (' + moment.duration(device.lastBatteryLeft * 1000).format('[d]d [h]h [m]m', {trim: false}) + ')';
              } else {
                device.batteryLeft = '';
              }
            }

            const remainingAttributesList: any[] = [];
            if ((device.totalDataRecords && device.totalDataRecords > 0)) {
              if (device.consecDupDataRecords || device.consecDupDataRecords === 0) {
                const qcPercent = +(((1 - (device.consecDupDataRecords / device.totalDataRecords)) * 100).toFixed(0));
                device.qc = qcPercent + '%';
              } else {
                device.qc = '100%';
              }
            } else {
              device.qc = 'n/a';
            }

            device.memoryStr = this.getMemoryStr(device);

            if (['tablet', 'CLOCK'].includes(device.deviceType)) {
              device.deviceIcon = 'tablet_android';
            } else if (!device.deviceType || device.deviceType === 'WATCH') {
              device.deviceIcon = 'watch';
            } else if (device.deviceType === 'ASSET_TRACKER') {
              device.deviceIcon = 'radar';
            }

            device.lastSeenIcon = this.getLastSeenIcon(device);

            for (const [key, value] of Object.entries(device)) {
              if (this.dateColumns.includes(key)) {
                const timeValue = value as Timestamp;
                device[key] = this.datePipe.transform(
                  timeValue.toMillis(),
                  'yyyy-MM-dd HH:mm'
                );
              }
            }

            if (device.deviceType === 'ASSET_TRACKER') {
              remainingAttributesList.push(
                [this.deviceRemainingAttributesReadableMap.get('id'), device.id ?? null],
                [this.deviceRemainingAttributesReadableMap.get('lastFlowerPickerWorkerName'), device.lastFlowerPickerWorkerName ?? null],
                [this.deviceRemainingAttributesReadableMap.get('lastSorterWorkerName'), device.lastSorterWorkerName ?? null],
                [this.deviceRemainingAttributesReadableMap.get('lastRowNumber'), device.lastRowNumber ?? null],
                [this.deviceRemainingAttributesReadableMap.get('lastVarietyName'), device.lastVarietyName ?? null],
                [this.deviceRemainingAttributesReadableMap.get('lastAsset'), `${device.lastAssetName ?? ''}${device.lastAssetNumber ?? ''}`.trim() ?? null],
                [this.deviceRemainingAttributesReadableMap.get('lastSessionId'), device.lastSessionId ?? null],
                [this.deviceRemainingAttributesReadableMap.get('serialNumber'), device.serialNumber ?? null],
                [this.deviceRemainingAttributesReadableMap.get('appModes'), device.assetTrackerAppMode ?? null],
                [this.deviceRemainingAttributesReadableMap.get('memory'), device.memoryStr ?? null]
              );
            } else {
              remainingAttributesList.push(
                [this.deviceRemainingAttributesReadableMap.get('id'), device.id ?? null],
                [this.deviceRemainingAttributesReadableMap.get('lastActivityTimestamp'), device.lastActivityTimestamp ?? null],
                [this.deviceRemainingAttributesReadableMap.get('lastActivityName'), device.lastActivityName ?? null],
                [this.deviceRemainingAttributesReadableMap.get('added'), device.added ?? null],
                [this.deviceRemainingAttributesReadableMap.get('lastGeoLocation'), device.lastGeoLocation ?? null],
                [this.deviceRemainingAttributesReadableMap.get('lastRowNumber'), device.lastRowNumber ?? null],
                [this.deviceRemainingAttributesReadableMap.get('enableAutoTouchLock'), device.enableAutoTouchLock],
                [this.deviceRemainingAttributesReadableMap.get('enableGPS'), device.enableGPS],
                [this.deviceRemainingAttributesReadableMap.get('enableOutOfRange'), device.enableOutOfRange],
                [this.deviceRemainingAttributesReadableMap.get('enableTrainingMode'), device.enableTrainingMode],
                [this.deviceRemainingAttributesReadableMap.get('enableVoiceAssistance'), device.enableVoiceAssistance],
                [this.deviceRemainingAttributesReadableMap.get('serialNumber'), device.serialNumber ?? null],
                [this.deviceRemainingAttributesReadableMap.get('appModes'), device.appModes ? Object.values(device.appModes).map((appMode: any) => appMode.name).join(', ') : []],
                [this.deviceRemainingAttributesReadableMap.get('memory'), device.memoryStr ?? null]
              );
            }


            remainingAttributesList.sort((n1, n2) =>
              n1 > n2 ? 1 : n1 < n2 ? -1 : 0
            );
            return {
              ...device,
              remainingAttributesList,
            };
          });

          this.dataSource = new MatTableDataSource(this.deviceList);

          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.filterProcessService.fliterPridecate(this.dataSource, true);
          this.filterProcessService.postProcessingFilterSort(this.columnsToDisplay, this.dataSource);
        });
  }

  fetchUnarchivedDevices() {
    this.deviceList = [];
    this.archivedDevicesSubscription?.unsubscribe();
    this.unArchivedDevicesSubscription =
      this.firestoreService.getUnArchivedDevicesForClientId(this.selectedClientDocData.id).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((devicesList) => {
        const currentlySelectedDeviceIds = (this.deviceList && this.deviceList.length > 0) ? this.deviceList.filter(device => device.isSelected).map(device => device.id) : [];
        this.deviceList = devicesList.map((device) => {
          device.deviceId = device.id;
          device.isSelected = currentlySelectedDeviceIds.includes(device.id);
          if (device.hasOwnProperty('isCharging') && device.isCharging) {
            if (device.lastBatteryLeft) {
              device.batteryLeft = (device.lastBatteryPercentage + '%' ?? '');
            } else {
              device.batteryLeft = '';
            }
          } else {
            if (device.lastBatteryLeft) {
              device.batteryLeft = (device.lastBatteryPercentage + '%' ?? '') + ' (' + moment.duration(device.lastBatteryLeft * 1000).format('[d]d [h]h [m]m', {trim: false}) + ')';
            } else {
              device.batteryLeft = '';
            }
          }
          const remainingAttributesList: any[] = [];
          if ((device.totalDataRecords && device.totalDataRecords > 0)) {
            if (device.consecDupDataRecords || device.consecDupDataRecords === 0) {
              const qcPercent = +(((1 - (device.consecDupDataRecords / device.totalDataRecords)) * 100).toFixed(0));
              device.qc = qcPercent + '%';
            } else {
              device.qc = '100%';
            }
          } else {
            device.qc = 'n/a';
          }

          device.memoryStr = this.getMemoryStr(device);

          if (['tablet', 'CLOCK'].includes(device.deviceType)) {
            device.deviceIcon = 'tablet_android';
          } else if (!device.deviceType || device.deviceType === 'WATCH') {
            device.deviceIcon = 'watch';
          } else if (device.deviceType === 'ASSET_TRACKER') {
            device.deviceIcon = 'radar';
          }

          device.lastSeenIcon = this.getLastSeenIcon(device);

          for (const [key, value] of Object.entries(device)) {
            if (this.dateColumns.includes(key)) {
              const timeValue = value as Timestamp;
              device[key] = this.datePipe.transform(
                timeValue.toMillis(),
                'yyyy-MM-dd HH:mm'
              );
            }
          }

          if (device.deviceType === 'ASSET_TRACKER') {
            remainingAttributesList.push(
              [this.deviceRemainingAttributesReadableMap.get('id'), device.id ?? null],
              [this.deviceRemainingAttributesReadableMap.get('lastFlowerPickerWorkerName'), device.lastFlowerPickerWorkerName ?? null],
              [this.deviceRemainingAttributesReadableMap.get('lastSorterWorkerName'), device.lastSorterWorkerName ?? null],
              [this.deviceRemainingAttributesReadableMap.get('lastRowNumber'), device.lastRowNumber ?? null],
              [this.deviceRemainingAttributesReadableMap.get('lastVarietyName'), device.lastVarietyName ?? null],
              [this.deviceRemainingAttributesReadableMap.get('lastAsset'), `${device.lastAssetName ?? ''}${device.lastAssetNumber ?? ''}`.trim() ?? null],
              [this.deviceRemainingAttributesReadableMap.get('lastSessionId'), device.lastSessionId ?? null],
              [this.deviceRemainingAttributesReadableMap.get('serialNumber'), device.serialNumber ?? null],
              [this.deviceRemainingAttributesReadableMap.get('appModes'), device.assetTrackerAppMode ?? null],
              [this.deviceRemainingAttributesReadableMap.get('memory'), device.memoryStr ?? null]
            );
          } else {
            remainingAttributesList.push(
              [this.deviceRemainingAttributesReadableMap.get('id'), device.id ?? null],
              [this.deviceRemainingAttributesReadableMap.get('lastActivityTimestamp'), device.lastActivityTimestamp ?? null],
              [this.deviceRemainingAttributesReadableMap.get('lastActivityName'), device.lastActivityName ?? null],
              [this.deviceRemainingAttributesReadableMap.get('added'), device.added ?? null],
              [this.deviceRemainingAttributesReadableMap.get('lastGeoLocation'), device.lastGeoLocation ?? null],
              [this.deviceRemainingAttributesReadableMap.get('lastRowNumber'), device.lastRowNumber ?? null],
              [this.deviceRemainingAttributesReadableMap.get('enableAutoTouchLock'), device.enableAutoTouchLock],
              [this.deviceRemainingAttributesReadableMap.get('enableGPS'), device.enableGPS],
              [this.deviceRemainingAttributesReadableMap.get('enableOutOfRange'), device.enableOutOfRange],
              [this.deviceRemainingAttributesReadableMap.get('enableTrainingMode'), device.enableTrainingMode],
              [this.deviceRemainingAttributesReadableMap.get('enableVoiceAssistance'), device.enableVoiceAssistance],
              [this.deviceRemainingAttributesReadableMap.get('serialNumber'), device.serialNumber ?? null],
              [this.deviceRemainingAttributesReadableMap.get('appModes'), device.appModes ? Object.values(device.appModes).map((appMode: any) => appMode.name).join(', ') : []],
              [this.deviceRemainingAttributesReadableMap.get('memory'), device.memoryStr ?? null]
            );
          }

          remainingAttributesList.sort((n1, n2) =>
            n1 > n2 ? 1 : n1 < n2 ? -1 : 0
          );
          return {
            ...device,
            remainingAttributesList,
          };
        });

        this.dataSource = new MatTableDataSource(this.deviceList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.filterProcessService.fliterPridecate(this.dataSource, true);
        this.filterProcessService.postProcessingFilterSort(this.columnsToDisplay, this.dataSource);
      });
  }

  // initiateFiltering() {
  //   if (this.filterValue) {
  //     this.dataSource.filter = this.filterValue.trim().toLowerCase();

  //     if (this.dataSource.paginator) {
  //       this.dataSource.paginator.firstPage();
  //     }
  //   }
  // }

  areArchivedDevicesShown() {
    return (this.unArchivedVsArchived === 'archived');
  }

  areUnArchivedDevicesShown() {
    return (this.unArchivedVsArchived === 'unarchived');
  }

  archiveDevice($event, device) {
    $event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: `Are you sure want to archive device '${device?.name ?? ''}' ?`,
        buttonText: {
          ok: 'Archive',
          cancel: 'Cancel'
        },
        device
      }
    });

    dialogRef.afterClosed().subscribe(async (value) => {
      if (value.decision) {
        try {
          await this.firestoreService.archiveDeviceById(value?.device?.id)
          this.snackBar.open(`Device '${value?.device?.name}' archived successfully`, '', {
            duration: 5000,
            panelClass: ['snackbar-success'],
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          });
        } catch (error) {
          this.snackBar.open(`Error in archiving devie '${value?.device?.name}'.\nPlease try again and/or contact support if problem persists`, '', {
            panelClass: ['snackbar-error'],
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          });
        }
      }
    });
  }

  async unarchiveDevice(device) {
    try {
      await this.firestoreService.unArchiveDeviceById(device?.id);
      this.snackBar.open(`Device '${device?.name}' unarchived successfully`, '', {
        duration: 5000,
        panelClass: ['snackbar-success'],
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    } catch (error) {
      this.snackBar.open(`Error in unarchiving device '${device?.name}'.\nPlease try again and/or contact support if problem persists`, '', {
        panelClass: ['snackbar-error'],
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    }
  }

  async saveIssue($event, device) {
    try {
      await this.firestoreService.updateIssueForDevice(device.id, device.hasIssue);
      this.snackBar.open(`Device issue ${device.hasIssue ? 'saved' : 'removed'} successfully`, '', {
        duration: 5000,
        panelClass: ['snackbar-success'],
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    } catch (error) {
      this.snackBar.open(`Error in ${device.hasIssue ? 'saving' : 'removing'} device issue`, '', {
        duration: 5000,
        panelClass: ['snackbar-error'],
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    }
  }

  toggleMultiSelect() {
    this.selectAll = !!this.selectAll;
    this.deviceList.forEach(device => {
      device.isSelected = this.selectAll;
    });
  }

  areMultipleDevicesSelected() {
    const selectedDevicesCount = this.deviceList?.filter(device => device.isSelected)?.length;
    return (selectedDevicesCount > 1);
  }

  determineIndeterminate() {
    const deviceCount = this.deviceList?.length;
    const selectedDevicesCount = this.deviceList?.filter(device => device.isSelected)?.length;
    if (selectedDevicesCount === deviceCount || selectedDevicesCount === 0) {
      return false;
    } else if (selectedDevicesCount > 0 && selectedDevicesCount < deviceCount) {
      return true;
    }
  }

  editMultipleDevices() {
    const dialogConfig = new MatDialogConfig();
    //dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      selectedDeviceIds: this.deviceList?.filter(device => device.isSelected).map(device => device.id)
    };
    this.dialog.open(MultipleDeviceEditComponent, dialogConfig);
  }

  archiveMultipleDevices() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: `Are you sure want to archive ${this.deviceList?.filter(device => device.isSelected).map(device => device.id).length} devices ?`,
        buttonText: {
          ok: 'Archive',
          cancel: 'Cancel'
        },
        selectedDeviceIds: this.deviceList?.filter(device => device.isSelected).map(device => device.id)
      }
    });

    dialogRef.afterClosed().subscribe(async (value) => {
      if (value.decision) {
        this.beingArchived = true;
        this.firestoreService.updateDevices(this.deviceList?.filter(device => device.isSelected).map(device => device.id), {isArchived: true})
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: async (apiResponse) => {
              this.beingArchived = false;
              if (apiResponse.success) {
                this.openSpecificSnackBar('All devices have been archived', 'success');
              } else if (apiResponse.success === false) {
                this.openSpecificSnackBar('Error in archiving devices:' + apiResponse.error, 'error');
              }
            },
            error: (error) => {
              this.beingArchived = false;
              this.openSpecificSnackBar('Error in archiving devices:' + error.message, 'error');
              console.log(error.message);
            }
          });
      }
    });
  }

  editEnrollmentSettings() {
    const dialogConfig = new MatDialogConfig();
    //dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      selectedClientDocData: this.selectedClientDocData
    };
    this.dialog.open(EnrollmentSettingsDialogComponent, dialogConfig);
  }

  getSelectedDeviceCount() {
    const selectedCount = this.deviceList?.filter(device => device.isSelected).map(device => device.id).length;
    if (selectedCount <= 1) {
      return '';
    } else {
      return `(${selectedCount})`;
    }
  }

  expandRow(element: any) {
    this.expandedElement = this.expandedElement?.deviceId === element.deviceId ? null : element;
  }

  applyExpandedClass(element: any) {
    return this.expandedElement?.deviceId === element.deviceId;
  }

  openSpecificSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  getLastSeenIcon(device) {
    if (device.lastSeenTimestamp) {
      if (moment(device.lastSeenTimestamp.toDate()).isBefore(moment().subtract(4, 'hours'))) {
        return 'red';
      } else {
        return 'green';
      }
    } else {
      return 'red';
    }
  }

  async visibilityClicked($event, device) {
    $event.stopPropagation();
    $event.preventDefault();
    await this.firestoreService.updateLastSeenForDevice(device.id);
  }

  async markLastSeenReqForMultiple() {
    this.lastSeenRequestInProgress = true;
    const deviceIds = this.deviceList?.map(device => device.id);
    try {
      await this.firestoreService.updateLastSeenForDevices(deviceIds);
      this.lastSeenRequestInProgress = false;
      this.openSpecificSnackBar('Last seen request raised for all devices', 'success');
    } catch (error: any) {
      this.lastSeenRequestInProgress = false;
      this.openSpecificSnackBar('Error in raising last seen request for all devices:' + error.message, 'error');
      console.log(`Error in setting last seen request for all devices:${JSON.stringify(error)}`);
    }
  }

  getMemoryStr(device) {
    let memoryStr = '';
    if (device.lastTotalAppSize && !isNaN(device.lastTotalAppSize)) {
      const appSize = +(device.lastTotalAppSize / (1024 * 1024)).toFixed(2);
      memoryStr = `Total app size: ${appSize} MB,`;
    } else {
      memoryStr = `Total app size: NA,`;
    }
    memoryStr += '    ';

    if (device.lastStorageSize && !isNaN(device.lastStorageSize)) {
      const storageSize = +(device.lastStorageSize / (1024 * 1024)).toFixed(2);
      memoryStr += `Storage size: ${storageSize} MB,`;
    } else {
      memoryStr += `Storage size: NA,`;
    }
    memoryStr += '    ';

    if (device.lastCacheSize && !isNaN(device.lastCacheSize)) {
      const cacheSize = +(device.lastCacheSize / (1024 * 1024)).toFixed(2);
      memoryStr += `Cache size: ${cacheSize} MB`;
    } else {
      memoryStr += `Cache size: NA`;
    }
    return memoryStr;
  }

}
