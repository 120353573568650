<div style="min-width: 400px; max-width: 500px;">
  <mat-dialog-content [formGroup]="multipleDeviceEditForm" style="text-align: center; max-height: 80vh;">
    <mat-form-field class="w-100 mb-3">
      <mat-label>Select Location</mat-label>
      <mat-select formControlName="locationId">
        <mat-option [value]="null">Select Location</mat-option>
        <mat-option *ngFor="let location of allLocationsList" [value]="location.id">
          {{location.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-100 mb-3">
      <mat-label>App Modes</mat-label>
      <mat-select formControlName="appModes" multiple>
        <mat-option *ngFor="let appMode of allAppModesList" [value]="appMode.id">
          {{appMode.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="toggler-options d-flex flex-column mb-3">
      <mat-slide-toggle class="edit_device_toggles text-left ml-0" formControlName="enableAutoTouchLock">
        <mat-label class="mb-0 ml-2">Enable auto touch lock</mat-label>
      </mat-slide-toggle>
      <mat-slide-toggle class="edit_device_toggles text-left ml-0" formControlName="enableGPS">
        <mat-label class="mb-0 ml-2">Enable GPS</mat-label>
      </mat-slide-toggle>
      <mat-slide-toggle class="edit_device_toggles text-left ml-0" formControlName="enableOutOfRange">
        <mat-label class="mb-0 ml-2">Enable out of range</mat-label>
      </mat-slide-toggle>
      <mat-slide-toggle class="edit_device_toggles text-left ml-0" formControlName="enableVoiceAssistance">
        <mat-label class="mb-0 ml-2">Enable voice assistance</mat-label>
      </mat-slide-toggle>
      <mat-slide-toggle class="edit_device_toggles text-left ml-0" formControlName="isLocked">
        <mat-label class="mb-0 ml-2">Lock device</mat-label>
      </mat-slide-toggle>
    </div>

  </mat-dialog-content>
  <div style="text-align: right;margin-bottom: 0.4rem;">
    <mat-dialog-actions>
      <button mat-stroked-button color="accent" (click)="close()" [disabled]="beingSaved">Close</button>
      <button mat-raised-button color="primary" (click)="updateDevices()" [disabled]="beingSaved"
        style="margin-left: 20px;">Save Changes</button>
    </mat-dialog-actions>
  </div>
</div>
