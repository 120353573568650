import { Injectable } from '@angular/core';
import { ActivitySession } from '../common/interfaces/new-table-interfaces';

@Injectable({
  providedIn: 'root'
})
export class FilterProcessingService {

  constructor() { }

fliterPridecate(dataSource:any, allString = false){
   dataSource.filterPredicate = (record, filter) => {
            const map = new Map(JSON.parse(filter));
            const returnValues = [];
  
            for (const [key, value] of map) {
              // @ts-ignore
              const recordValue: string | number | boolean | undefined =
                record[key as keyof ActivitySession];
  
              if (typeof recordValue === 'string' && !allString) {
                
                const regex = new RegExp(`${value}`, 'i');
                returnValues.push(regex.test(recordValue));
              }
              if (typeof recordValue === 'string' && allString) {
                const escapedValue = this.escapeRegExp(value); // Escape special characters in value
                const regex = new RegExp(`${escapedValue}`, 'i'); 
                returnValues.push(regex.test(recordValue)); 
              }
              if (typeof recordValue === 'number') {
                returnValues.push(recordValue === Number(value));
              }
              if (typeof recordValue === 'boolean') {
                returnValues.push(recordValue === value);
              }
            }
            return returnValues.every(Boolean);
          };
}
 escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escapes special characters
}

   postProcessingFilterSort(columns:any, dataSource:any): void {
      columns.forEach((column, index) => {
        if (
          dataSource.sort?.active === column.name ||
          column.filterValue !== ''
        ) {
          column.filtered = true;
        } else {
          column.filtered = false;
        }
      });
  
      columns.forEach((column, columnIndex) => {
        columns[columnIndex].filterOptions = [];
        const optionsSet: Set<any> = new Set();
        dataSource.filteredData.forEach((row, rowIndex) => {
          for (const [key, value] of Object.entries(row)) {
            if (key === column.name) {
              optionsSet.add(value);
            }
          }
        });
        column.filterOptions = [...optionsSet].sort();
      });
  
      columns.forEach((column, columnIndex) => {
        const footerType = columns[columnIndex].footerType;
        const filteredDatasource: any[] = [];
        dataSource.filteredData.forEach((row, rowIndex) => {
          for (const [key, value] of Object.entries(row)) {
            if (key === column.name) {
              filteredDatasource.push(value);
            }
          }
        });
        if (footerType === 'total') {
          column.footerValue = 'Total';
        }
        if (footerType === 'sum') {
          column.footerValue = filteredDatasource.reduce(
            (accumulator, currentValue) => {
              return accumulator + currentValue;
            },
            0
          );
        }
        if (footerType === 'count') {
          column.footerValue = filteredDatasource.length;
        }
      });
    }
}
