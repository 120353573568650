import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

export interface MenuItem {
  displayName: string;
  url?: string;
  children?: MenuItem[];
}
@Component({
  selector: 'app-casting-menu-item',
  templateUrl: './casting-menu-item.component.html',
  styleUrl: './casting-menu-item.component.scss'
})
export class CastingMenuItemComponent implements OnInit{
  @Input() key: string;
  @Input() items: MenuItem[];
  @ViewChild('childMenu') public childMenu: any;
  castingKey: string;

  constructor(private clipboard: Clipboard) {}

  copyUrl(url: string | undefined) {
    if (url) {
      url = `https://${url}&key=${this.castingKey}`;
      this.clipboard.copy(url);
    }
  }

  ngOnInit(): void {
    if (this.key) {
      this.castingKey = this.key;
    }
  }
}
