<div class="listing-container">
  <div class="controls-container">
    <button mat-flat-button color="primary" class="setting-add-button mr-3" (click)="openDialog()"
      *ngIf="selectedClientDocData?.hasManagerOrAdminRole">
      <mat-icon matTooltip="Create Worker" matTooltipClass="tooltip-class" matTooltipHideDelay="100000">
        add
      </mat-icon>{{'SETTINGS.WORKER.CREATEWORKER.BUTTON.TEXT' | translate}}</button>

    <span class="f-grow"></span>
    <button mat-stroked-button color="primary" class="setting-add-button mr-3" (click)="openLabels()">
      <mat-icon class="material-symbols-outlined" matTooltip="Labels" matTooltipClass="tooltip-class"
        matTooltipHideDelay="100000">
        label
      </mat-icon> {{'SETTINGS.LABELS.BUTTON.TEXT' | translate}}</button>
    <button mat-stroked-button color="primary" class="setting-add-button mr-3" (click)="openWorkerGroup()">
      <mat-icon matTooltip="Worker Groups" matTooltipClass="tooltip-class" matTooltipHideDelay="100000">
        group
      </mat-icon> {{'SETTINGS.WORKERGROUPS.BUTTON.TEXT' | translate}}</button>

    <div>
      <mat-button-toggle-group #group="matButtonToggleGroup" [hideSingleSelectionIndicator]="true"
        [(ngModel)]="unArchivedVsArchived" (change)="toggleArchived()">
        <mat-button-toggle value="unarchived" aria-label="Text align left">
          <mat-icon matTooltip="Show users" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
            matTooltipPosition="below">list
          </mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="archived" aria-label="Text align center">
          <mat-icon matTooltip="Show archived users" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
            matTooltipPosition="below">archive
          </mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div class="table-card">
    <div *ngIf="areArchivedWorkersShown()">{{'SETTINGS.WORKERS.ARCHIVEDWORKERS.BUTTON.TEXT' | translate}}</div>
    <div class="mat-elevation-z8" style="clear: both;">
      <table class="listing-table" mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
        <ng-container [matColumnDef]="column.name" *ngFor="let column of columnsToDisplay">
          <th mat-header-cell *matHeaderCellDef>
            <div class="table-header-container">
              <div class="table-header-content-text">{{column.displayName | translate}}</div>
              <div class="table-header-content-menu">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                  class="menu-button" *ngIf="column.showHeaderFilter" [class]="column.filtered ? 'filtered' : ''">
                  <mat-icon>filter_list</mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="menu customize" xPosition="before">
                  <app-table-filter [column]="column" [columns]="columnsToDisplay"
                    [displayedTopFilters]="displayedTopFilters" [dataSource]="dataSource"></app-table-filter>
                </mat-menu>
              </div>
            </div>


          </th>
          <td mat-cell *matCellDef="let element">{{element[column.name]}}</td>
        </ng-container>

        <ng-container matColumnDef="isLeftHanded">
          <th mat-header-cell *matHeaderCellDef>{{'SETTINGS.TABLEHEAD.LEFTHANDED.TITLE.TEXT' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="listing-table-tools-wrapper">
            <mat-icon class="material-symbols-outlined" *ngIf="element.isLeftHanded">
              check
            </mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="Edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="listing-table-tools-wrapper">
            <div class="grid-control-column">
              <button mat-icon-button color="accent" (click)="openEditDialog(element)" class="listing-table-tools"
                [disabled]="!selectedClientDocData?.hasManagerOrAdminRole">
                <mat-icon>edit</mat-icon>
              </button>

              <button mat-icon-button color="accent" (click)="archiveWorker(element)" class="listing-table-tools"
                [disabled]="!selectedClientDocData?.hasManagerOrAdminRole" *ngIf="!element.isArchived">
                <mat-icon matTooltip="Archive this worker" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
                  matTooltipPosition="below">archive</mat-icon>
              </button>
              <button mat-icon-button color="accent" (click)="unarchiveWorker(element)" class="listing-table-tools"
                [disabled]="!selectedClientDocData?.hasManagerOrAdminRole" *ngIf="element.isArchived">
                <mat-icon matTooltip="Unarchive this worker" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
                  matTooltipPosition="below">unarchive</mat-icon>
              </button>
            </div>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsHeadersToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsHeadersToDisplay"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="100"
        [showFirstLastButtons]="true"></mat-paginator>
    </div>
  </div>
</div>
