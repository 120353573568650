<mat-icon [matTooltip]="getTooltipForStreamingButton()" [inline]="true" (click)="toggleStreaming()"
  class="floating-play-btn" *ngIf="tableData?.length > 0">
  {{displayedIcon}}
</mat-icon>
<div class="inner-page-action">
  <app-date-selection></app-date-selection>
</div>
<div class="listing-container table-card">
  <div *ngIf="tableData?.length === 0">
    {{'DASHBOARD.TABEL.SELECTEDDATE.NODATA.T_CONTENT.TEXT' | translate}}
  </div>
  <div *ngIf="tableData?.length > 0" class="mat-elevation-z8" style="clear: both;"
    [style.display]="isLaborOverviewTableDisplayed()">
    <div class="table-body overflow-auto table-mobile-res">
      <div class="table-responsive">
        <table mat-table [dataSource]="laborOverviewDataSource" multiTemplateDataRows matSort class="listing-table layout-fixed-sm">
          <ng-container [matColumnDef]="column.name" *ngFor="let column of columnsToDisplay">
            <th mat-header-cell *matHeaderCellDef >
              <div class="table-header-container">
                <div class="table-header-content-text">{{column.displayName | translate}}</div>
                <div class="table-header-content-menu">
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                    class="menu-button" *ngIf="column.showHeaderFilter" [class]="column.filtered ? 'filtered' : ''">
                    <mat-icon>filter_list</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" class="menu customize" xPosition="before">
                    <app-table-filter [column]="column" [columns]="columnsToDisplay" [displayedTopFilters]="displayedTopFilters" [dataSource]="laborOverviewDataSource"></app-table-filter>
                  </mat-menu>
                </div>
              </div>
              
              
            </th>
            <td mat-cell *matCellDef="let element">{{element[column.name]}}</td>
            <mat-icon class="material-symbols-outlined">
              visibility
            </mat-icon>
          </ng-container>
          
          <ng-container matColumnDef="startTime">
            <th *matHeaderCellDef mat-header-cell class="hide-sm">{{'DASHBOARD.TABLEHEAD.TIME.TITLE.TEXT' | translate}}</th>
            <td mat-cell *matCellDef="let element" class="hide-sm">
              {{element.startTime}}
            </td>
          </ng-container>
          <ng-container matColumnDef="rowNumber">
            <th *matHeaderCellDef mat-header-cell>{{'DASHBOARD.TABLEHEAD.ROW.TITLE.TEXT' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              {{element.rowNumber}}
            </td>
          </ng-container>
          <ng-container matColumnDef="trolleyNumber">
            <th *matHeaderCellDef mat-header-cell class="hide-sm">{{'DASHBOARD.TABLEHEAD.TROLLEY.TITLE.TEXT' | translate}}</th>
            <td mat-cell *matCellDef="let element" class="hide-sm">
              {{element.trolleyNumber}}
            </td>
          </ng-container>
          <ng-container matColumnDef="netPerformance">
            <th *matHeaderCellDef mat-header-cell class="hide-sm">{{'DASHBOARD.TABLEHEAD.PERFORMANCE.TITLE.TEXT' | translate}}</th>
            <td mat-cell *matCellDef="let element" class="hide-sm">
              {{element.netPerformance}}
            </td>
          </ng-container>
          <ng-container matColumnDef="red-dot">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="listing-table-tools">
              <mat-icon *ngIf="element.avgWorkerPerfRelToGroupPast > 0" class="material-symbols-outlined success">
                arrow_upward
              </mat-icon>
              <mat-icon *ngIf="element.avgWorkerPerfRelToGroupPast < 0" class="material-symbols-outlined failure">
                arrow_downward
              </mat-icon>
              <img [mtxTooltip]="redDotTooltip" ngSrc="assets/lightgray.ico" height="18" width="18"
                style="margin-top: 4px;" *ngIf="(element.isAnyNestedRowRed)">
              <ng-template #redDotTooltip>
                <div
                  style="background: #5b5f66; color: mintcream;padding:4px;border-radius:5px; text-align: center;font-size: 0.92rem;width:12rem !important;">
                  <div style="margin-bottom: 4px;">Check if the session is correct</div>
                  <span style="">Gross Performance is {{element.grossPerformance}}</span> <br />
                  <span style="">Net Performance is {{element.netPerformance}}</span> <br />
                </div>
              </ng-template>
            </td>
          </ng-container>
         
  
  
          <tr mat-header-row *matHeaderRowDef="columnsHeadersToDisplay"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsHeadersToDisplay"
            [class.expanded-row]="applyExpandedClass(element)" (click)="openDialog(element)">
  
          </tr>
  
        </table>
      </div>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="100" [showFirstLastButtons]="true"></mat-paginator>
    </div>


  </div>
</div>
